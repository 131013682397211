<template>

    <div class='grid'>
        <div class='col-12 md:col-6 lg:col-12'>
            <div class='p-0 h-full flex flex-column'>

                <div class='grid'>

                    <div class='col-12 lg:col-8'>
                        <h5 class='mb-0 mt-3'><i class="pi pi-check mr-2"></i> Proje Personel Tarihçesi</h5>
                    </div>

                    <div class='col-12 lg:col-4 text-right'>


                        <Button label='Excele Aktar' icon='pi pi-file-excel' class='p-button-success' @click='exportExcel'></Button>

                    </div>

                </div>

                <DataTable
                    :value='dataList'
                    :paginator='true'
                    class='p-datatable-gridlines'
                    :rows='6'
                    dataKey='id'
                    :rowHover='true'
                    v-model:filters='filters'
                    filterDisplay='menu'
                    :loading='loadingIndicator'
                    :filters='filters'
                    responsiveLayout='scroll'
                    :globalFilterFields="['employee.name','employee.surName','employee.identityNumber','employee.gsm','employee.email','bankName'] ">

                    <template #header>
                        <div class='flex justify-content-between flex-column sm:flex-row'>
                            <Button type='button' icon='pi pi-filter-slash' label='Temizle'
                                    class='p-button-outlined mb-2' @click='clearFilter()' />
                            <span class='p-input-icon-left mb-2'>
                                <i class='pi pi-search' />
                                <InputText v-model="filters['global'].value" placeholder='Arama' style='width: 100%' />
                            </span>
                        </div>
                    </template>

                    <template #empty>
                        Veri bulunamadı.
                    </template>

                    <template #loading>
                        Proje Personel listesi yükleniyor. Lütfen bekleyin.
                    </template>

                    <Column header='Profil' bodyClass="text-center" style='width:4rem'>
                            <template #body='{data}'>
                                <img :alt='data.employeePhoto' :src="'http://image.a1catering.com.tr/' + data.employee.employeePhoto" class='border-circle border-0 border-solid cursor-pointer' width='80' height='80' style='vertical-align: middle' @click='edit(data)'/>
                            </template>
                    </Column>

                    <Column field='employee.name' header='Ad' style='width:13rem'>
                        <template #body='{data}'>
                                <a @click='edit(data)' class="cursor-pointer">
                                    {{ data.employee.name }}
                                </a>
                        </template>
                    </Column>

                    <Column field='employee.surName' header='Soyad' style='width:13rem'>
                        <template #body='{data}'>
                                <a @click='edit(data)' class="cursor-pointer">
                                    {{ data.employee.surName }}
                                </a>
                        </template>
                    </Column>

                    <Column field='employee.identityNumber' header='T.C. Kimlik' filterField='employee.identityNumber' :showFilterMatchModes='false' style='width:8rem'>
                    </Column>

                    <Column header='Doğum Tarihi' filterField='employee.birthday' field='employee.birthday' dataType='date' :sortable='false'>
                        <template #body='{data}'>
                            {{ formatDate(data.employee.birthday) }}
                        </template>
                    </Column>

                    <Column header='Cinsiyet' field='gender' dataType='gender' :sortable='false' style='width:7rem'>
                        <template #body='{data}'>
                            {{ data.employee.gender }}
                        </template>
                    </Column>

                    <Column field='employee.gsm' header='GSM' filterField='employee.gsm' :showFilterMatchModes='false' style='width:8rem'>
                        <template #body='{data}'>
                            <a :href="'tel:+90' + data.employee.gsm" v-if='data.employee.gsm != null && data.employee.gsm != ""'>{{ data.employee.gsm }}</a>
                        </template>
                    </Column>

                    <Column field='employee.email' header='E-Posta' filterField='email' :showFilterMatchModes='false' style='width:12rem' class="hidden">
                        <template #body='{data}'>
                            <a :href="'mailto:' + data.employee.email" v-if='data.employee.email != null && data.employee.email != ""'>{{ data.employee.email }}</a>
                        </template>
                    </Column>

                    <Column header='Maaş' filterField='officialSalary' dataType='date' style='width:10rem'>
                        <template #body='{data}'>
                            <b class="text-green-600">{{ formatPrice(data.officialSalary) }}</b>
                        </template>
                    </Column>

                    <Column header='Diğer Maaş' filterField='startDate' dataType='date' style='width:10rem'>
                        <template #body='{data}'>
                            <b class="text-red-600">{{ formatPrice(data.unOfficialSalary) }}</b>
                        </template>
                    </Column>

                    <Column header='Toplam Maaş' filterField='startDate' dataType='date' style='width:10rem'>
                        <template #body='{data}'>
                            <b>{{ formatPrice(data.unOfficialSalary + data.officialSalary) }}</b>
                        </template>
                    </Column>

                    <Column header='Banka' filterField='bankName' dataType='date' style='width:10rem'>
                        <template #body='{data}'>
                            {{data.bankName }}
                        </template>
                    </Column>

                    <Column header='IBAN' filterField='iban' dataType='date' style='width:10rem'>
                        <template #body='{data}'>
                            {{ data.iban }}
                        </template>
                    </Column>

                    <Column header='Giriş Tarihi' filterField='startDate' dataType='date' style='width:10rem'>
                        <template #body='{data}'>
                            <b class="text-green-600">{{ formatDate(data.startDate) }}</b>
                        </template>
                    </Column>

                    <Column header='Çıkış Tarihi' filterField='endDate' dataType='date' style='width:10rem'>
                        <template #body='{data}'>
                            <b class="text-red-600">{{ formatDate(data.endDate) }}</b>
                        </template>
                    </Column>

                    <Column header='Çıkış Sebebi' filterField='passiveReason' style='width:10rem'>
                        <template #body='{data}'>
                            {{ data.passiveReason }}
                        </template>
                    </Column>

                    <Column header='Oluşturma Tarihi' filterField='insertDate' dataType='date' style='width:10rem'>
                        <template #body='{data}'>
                            {{ formatDate(data.insertDate) }}
                        </template>
                    </Column>

                    <Column field='verified' header='Emekli Durum' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isRetired, 'text-pink-500 pi-times-circle': !data.isRetired}"></i>
                        </template>
                    </Column>
                    
                    <Column field='verified' header='SGK Giriş' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isSgkProcessDone, 'text-pink-500 pi-times-circle': !data.isSgkProcessDone}"></i>
                        </template>
                    </Column>

                    <Column field='verified' header='Durum' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                        <template #body='{data}'>
                            <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isActive, 'text-pink-500 pi-times-circle': !data.isActive}"></i>
                        </template>
                    </Column>

                    <Column header="Düzenle" bodyClass="text-center" style="width:3rem" class="hidden">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" @click="edit(slotProps.data)"/>
                        </template>
                    </Column>

                </DataTable>
            </div>

        </div>

    </div>


</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import moment from 'moment';
import EmployeeService from '../../services/employeeService';
import ProjectEmployeeService from '../../services/projectEmployeeService';
import { getActiveProjectId } from '@/pages/common/commonConstantFunctions';

export default {
    data() {
        return {
            dataList: [],
            filters: null,
            loadingIndicator: true,
            salaryReport:{},
            employeeListReport:{
                totalPersonal : 0,
                totalPrice : 0,
                totalOfficialPrice:0,
                totalUnOfficialPrice:0
            },
        };
    },
    _firmService: null,
    _projectEmployeeService: null,
    created() {
        this.projectId = getActiveProjectId();
        this._employeeService = new EmployeeService();
        this._projectEmployeeService = new ProjectEmployeeService();
        this.initFilters();
        this.loadingIndicator = true;
    },

    async mounted() {
        await this.refreshProjectEmployeeList();
    },
    methods: {
        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
                },
                'isActive': {
                    operator: FilterOperator.AND,
                    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
                },
            };
        },
        async exportExcel() {
            this.loadingIndicator = true;
            let excelExports = await this._projectEmployeeService.getProjectEmployeesHistoryExcel(this.projectId);
            let blob = new Blob([excelExports.data], { type: excelExports.headers['content-type'] });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Proje Personel Listesi " + moment(this.paymentDate).format('DD.MM.YYYY') + ".xlsx";
            link.click();
            this.loadingIndicator = false;
        },
        async refreshProjectEmployeeList() {
            const response = await this._projectEmployeeService.getProjectEmployeesHistory(this.projectId);
            if (response.isSuccess) {
                this.dataList = response.data;
            }

            this.loadingIndicator = false;
        },

        clearFilter() {
            this.initFilters();
        },

        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
        formatPrice(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        edit(item) {
            this.$router.push({ name: 'editProjectEmployee', params: { employeeId: item.employeeId, projectEmployeeId: item.id } });
        },
    },
};
</script>

<style scoped lang='scss'>
//@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
    font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
    font-weight: bold;
}

::v-deep(.p-progressbar) {
    height: .5rem;
    background-color: #D8DADC;

    .p-progressbar-value {
        background-color: #607D8B;
    }
}
</style>
